<template>
  <img class="background-container" src="/src/assets/images/404.png" />
</template>

<script>
export default {
  name: 'ImageComponent',
}
</script>

<style scoped>
.background-container {
  width: 100%;
  height: 360px;
  margin: 0px 0px 10px 60px;
  padding: 0;
}
</style>
